import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Layout from '../../components/Layout/Layout'
import { ContentfulPage, ContentfulText } from '../../../types/graphql-types'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const HeroBackground = styled.section`
  ${tw`max-w-1920 mx-auto md:h-64`}
`
const HeroTextBox = styled.div`
  ${tw`flex content-center flex-wrap h-full`}
  div {
    ${tw`container py-12 text-left text-white`}
    h1 {
      ${tw`leading-none mb-4 w-1/2`}
    }
  }
`

export const query = graphql`
  query CpniOptOut {
    contentfulPage(externalName: { eq: "CPNI Opt Out" }) {
      slug
      title
      seoTitle
      seoDescription
      noindex
      references {
        ... on ContentfulText {
          id
          externalName
          shortSimpleText
        }
      }
    }
  }
`

const CpniOptOutPage: React.FC<Props> = ({ data }: Props) => {
  return (
    <Layout>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        noIndex={data.contentfulPage?.noindex as boolean}
      />
      <HeroBackground className="gradient-5-lively">
        <HeroTextBox>
          <div>
            <h1>{data.contentfulPage.title}</h1>
          </div>
        </HeroTextBox>
      </HeroBackground>
      {data.contentfulPage.references?.map(content => {
        const text = content as ContentfulText
        if (text.externalName === 'Feature Content Copy') {
          return (
            <iframe
              key={text.id}
              src={text.shortSimpleText as string}
              frameBorder="0"
              width="100%"
              height="800"
              title={data.contentfulPage.title as string}
              style={{ overflow: 'hidden' }}
            ></iframe>
          )
        }
      })}
    </Layout>
  )
}

export default CpniOptOutPage
